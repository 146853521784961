import { Controller } from "@hotwired/stimulus"

import Choices from "choices.js"

export default class extends Controller {
  connect() {
    if (!this.element._choices) {
      this.initializeChoices();
    }
  }

  initializeChoices() {
    new Choices(this.element, {
      removeItemButton: true,
      searchEnabled: true,
      itemSelectText: '',
      maxItemCount: 5,
    });
  }

  redirect(event) {
    const selectedStarId = event.target.value
    if (selectedStarId) {
      Turbo.visit(`/stars/${selectedStarId}`);
    }
  }
}